import React, { useState } from 'react';
import {Stepper, Step, StepLabel, Typography, Box, Button, Modal} from '@mui/material';
import New from './New.jpg'
const steps = ['Start', 'Show', 'Confirm'];
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function BeautyForm() {
 const [activeStep, setActiveStep] = useState(0);
  const [open, setOpen] = useState(false);
const [modalText, setModalText] = useState('');

 const handleNext = () => {

    setActiveStep(prevActiveStep => prevActiveStep + 1);
 };

 const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
 };

 const handleReset = () => {
    setActiveStep(0);
 };
 const handleClose = () => {
    setOpen(false);
 };
 return (
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
      <div style={{width: '70%'}}>

        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Box component="div" sx={{ padding: 2 }}>
          {activeStep === steps.length ? (
            <div>
              <Typography variant="h5" gutterBottom>
                All steps completed
              </Typography>
              <Button onClick={handleReset}>Reset</Button>
            </div>
          ) : (
            <div>
              <Typography variant="h6" gutterBottom>
                {getStepContent(activeStep)}
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                 color="inherit"
                 disabled={activeStep === 0}
                 onClick={handleBack}
                >
                 Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleNext}>
                 {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </Box>
            </div>
          )}
        </Box>
          <Modal
  open={activeStep === steps.length - 1}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    <img src={New} alt="no img" width="90%" height="90%"/>
  </Box>
</Modal>
      </div>
    </div>
 );
}

function getStepContent(step) {
 switch (step) {
    case 0:
      return 'Final Exam';
    case 1:
       return `Your random number is: ${Math.floor(Math.random() * 11) + 1}`;
    case 2:
      return 'Confirm your details here';
    default:
      return 'Unknown step';
 }
}

export default BeautyForm;