import logo from './logo.svg';
import './App.css';
import BeautyForm from "./BeautyForm";
function App() {
  return (

     <BeautyForm/>

  );
}

export default App;
